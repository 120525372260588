import {
  register as registerType,
  login as loginType,
  logout as logoutType,
  update as updateType,
  getById as getUserById,
  get as getUsers,
  getAndConcat as getUsersAndConcat
} from '../types/user.js'
import axios from 'axios'
import { navigate } from '@reach/router'

const baseURL = process.env.GATSBY_API_URL
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL
})

export const register = dispatch => user => {
  dispatch({ type: registerType.REQUEST, data: user})
  axiosInstance.post('user', user)
    .then(response => dispatch({type: registerType.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: registerType.FAIL}))
}

export const cleanRegister = dispatch => {
  dispatch({ type: registerType.CLEAN})
}

export const login = dispatch => user => {
  dispatch({ type: loginType.REQUEST })
  axiosInstance.post('user/login', user)
    .then(response => dispatch({type: loginType.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: loginType.FAIL}))
}

export const getSession = dispatch => (pathname) => {
  dispatch({ type: loginType.REQUEST })
  axiosInstance.get('user/login')
    .then(response => dispatch({type: loginType.SUCCESS, data: response.data}))
    .catch(error => {
      dispatch({type: loginType.FAIL})
      console.log(pathname)
      if(
        pathname === '/new-project/' || 
        pathname === '/profile/'  || 
        pathname === '/project-details/'  ||
        pathname === '/proposal'  ||
        pathname === '/provider-profile/'
      ) {
        navigate('/login')
      }
    })
}

export const update = dispatch => (payload) => {
  dispatch({ type: updateType.REQUEST })
  axiosInstance.patch('user', payload)
    .then(response => dispatch({type: updateType.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: updateType.FAIL}))
}

export const getById = dispatch => userId => {
  dispatch({ type: getUserById.REQUEST})
  axiosInstance.get(`/user/${userId}`)
    .then(response => dispatch({type: getUserById.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getUserById.FAIL}))
}


export const get = dispatch => queryParams => {
  const queryString = Object.keys(queryParams).reduce((acc, current, index) => `${acc}${index > 0 ? '&': ''}${current}=${queryParams[current]}`, '?')
  dispatch({ type: getUsers.REQUEST})
  axiosInstance.get(`/user${queryString}`)
    .then(response => dispatch({type: getUsers.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getUsers.FAIL}))
}

export const getAndConcat = dispatch => queryParams => {
  const queryString = Object.keys(queryParams).reduce((acc, current, index) => `${acc}${index > 0 ? '&': ''}${current}=${queryParams[current]}`, '?')
  dispatch({ type: getUsersAndConcat.REQUEST})
  axiosInstance.get(`/user${queryString}`)
    .then(response => dispatch({type: getUsersAndConcat.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getUsersAndConcat.FAIL}))
}


export const logout = dispatch => () => {
  dispatch({ type: logoutType.REQUEST })
  axiosInstance.head('user/logout')
    .then(response => dispatch({type: logoutType.SUCCESS}))
    .catch(error => dispatch({type: logoutType.FAIL}))
}
